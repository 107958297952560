import { useState } from "react";
import Message from "./Message"; // Assuming you have a Message component for individual messages
import { FaLink, FaPaperPlane } from 'react-icons/fa';
const ChatArea = ({
  selectedSection,
  messages,
  inputMessage,
  setInputMessage,
  onSendMessage,
  onPromptClick,
}) => {
  const prompts = [
    "Help me with Homework",
    "Reasoning Questions",
    "Critical Thinking",
    "AI-Generated Answers",
    "Guided Learning",
    "Assignment Writer",
    "More",
  ];
  const [text, setText] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('Physics');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLinkDropdownOpen, setIsLinkDropdownOpen] = useState(false);
  const [selectedLinkOption, setSelectedLinkOption] = useState("Link");
  const linkOptions = ["Document", "Image"];

  const categories = ['Physics', 'Chemistry', 'Math', 'Biology'];
  const handleLinkOptionSelect = (option) => {
    setSelectedLinkOption(option);
    setIsLinkDropdownOpen(false);
  };
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  // Calculate word count based on inputMessage
  const wordCount = inputMessage.trim() ? inputMessage.trim().split(/\s+/).length : 0;

  return (
    <div className="flex flex-col justify-between h-screen p-10 bg-white">
      <div className="flex flex-col items-center">
        <h1 className="text-3xl font-bold mb-2 text-gray-800">Ask me anything</h1>
        <p className="text-gray-500 mb-8">Choose a prompt and start your journey!</p>

        {/* Only show prompts if there are no messages */}
        {messages.length === 0 && (
          <div className="grid grid-cols-3 gap-6 my-8">
            {prompts.map((prompt, index) => (
              <button
                key={index}
                onClick={() => onPromptClick(prompt)}
                className={`py-3 px-6 rounded-full border hover:bg-purple-600 hover:text-white ${inputMessage === prompt
                    ? "bg-purple-600 text-white"
                    : "border-gray-400 text-gray-400"
                  } ${
                  // Center the last prompt if it's the only one in the last row
                  index === prompts.length - 1 && prompts.length % 3 !== 0
                    ? "col-start-2"
                    : ""
                  }`}
              >
                {prompt}
              </button>
            ))}
          </div>

        )}
      </div>

      <div className="flex-1 overflow-y-auto mb-8">
        {messages &&
          messages.map((message, index) => (
            <Message key={index} message={message} />
          ))}
      </div>

      {/* Updated Input Section */}
   
      <div className="relative flex flex-col items-start  p-2 md:p-4  rounded-xl shadow-lg w-full  mx-auto">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Ask AI anything or make a request"
          className="flex-1 bg-transparent outline-none w-full border-none focus:outline-none focus:ring-0 focus:appearance-none text-gray-800 placeholder-gray-500 text-sm md:text-base"
        />

        <div className="relative flex justify-between items-center p-2 md:p-4 w-full">
          {/* Dropdown */}
          <div
            className="relative flex items-center gap-8 border border-purple-300 text-purple-700 rounded-full px-3 py-1.5 cursor-pointer select-none mr-2"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {selectedCategory}
            <span className="ml-2">&#9662;</span>

            {/* Dropdown Menu */}
            {isDropdownOpen && (
              <div className="absolute top-full left-0 mt-2 bg-white border border-purple-300 rounded-lg shadow-lg w-28 z-10">
                {categories.map((category) => (
                  <div
                    key={category}
                    className="px-4 py-2 hover:bg-purple-100 text-purple-700 cursor-pointer"
                    onClick={() => handleCategorySelect(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Input Field */}


          {/* Word Count */}
       

          {/* Action Buttons */}
          <div className="flex items-center gap-2">
          <span className="text-gray-400 text-xs mr-2 hidden md:inline">({wordCount}/2000 words)</span>
          <div className="relative">
          <button
            className="bg-purple-100 border border-purple-300 text-purple-700 rounded-full p-2 hover:bg-purple-300 hover:text-white"
            onClick={() => setIsLinkDropdownOpen(!isLinkDropdownOpen)}
          >
            <FaLink />
          </button>

          {/* Link Dropdown Menu */}
          {isLinkDropdownOpen && (
            <div className="absolute top-full right-0 mt-2 bg-white border border-purple-300 rounded-lg shadow-lg w-28 z-10">
              {linkOptions.map((option) => (
                <div
                  key={option}
                  className="px-4 py-2 hover:bg-purple-100 text-purple-700 cursor-pointer"
                  onClick={() => handleLinkOptionSelect(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
            <button   onClick={onSendMessage} className="bg-purple-100 border border-purple-300 text-purple-700 rounded-full p-2 hover:bg-purple-300 hover:text-white">
              <FaPaperPlane />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatArea;
