import React, { useState } from "react";

const OTPVerification = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleChange = (e, index) => {
    const { value } = e.target;
    if (/^\d*$/.test(value)) {  // Only allows numbers
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Focus the next input field if a number is entered
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
    }
  };

  const handleResendCode = () => {
    // Logic to resend the OTP code
    alert("Resend OTP Code");
  };

  return (
    <div className="w-full mx-auto p-8">
      <div className="mx-auto w-[90%] max-w-md">
        <h2 className="text-3xl mx-auto font-bold text-gray-800">OTP Verification</h2>
        <p className="text-gray-500 mx-auto my-4">We have sent a 4-digit verification code to your email address <span className="font-medium text-gray-800">abc.xyz@gmail.com</span>.</p>

        {/* OTP Input Fields */}
        <div className="flex gap-3 justify-center my-8">
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              value={digit}
              maxLength="1"
              onChange={(e) => handleChange(e, index)}
              className="w-16 h-16 border border-gray-300 rounded-full text-center text-2xl focus:outline-none focus:ring-2 focus:ring-purple-500"
            />
          ))}
        </div>

        {/* Resend Code Option */}
        <p className="text-gray-500 text-center mt-4">
          Didn’t receive code?{" "}
          <button
            onClick={handleResendCode}
            className="text-purple-600 font-medium"
          >
            Resend it
          </button>
        </p>

        {/* Reset Password Button */}
        <div className="flex flex-col gap-4 items-center mt-6">
          <button
            type="submit"
            className="py-3 bg-purple-600 w-3/4 mx-auto text-white rounded-full font-semibold hover:bg-purple-700 transition"
          >
            Reset Password
          </button>
        </div>

        {/* Footer Links */}
        <p className="my-2 text-sm text-center text-gray-600">
          By creating an account, you agree to our{' '}
          <span className="text-purple-600">Terms & Conditions</span> and{' '}
          <span className="text-purple-600">Privacy Policy</span>.
        </p>
      </div>
    </div>
  );
};

export default OTPVerification;
