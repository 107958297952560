// src/layouts/AuthLayout.js
import React from "react";
import { Outlet } from "react-router-dom";
import img from "../assets/Students.png";
import bgImage from "../assets/BG-gradient.png";

import { Route, Routes } from "react-router-dom";
import AuthForm from "../components/Auth/AuthForm";
import AuthNav from "../components/Auth/AuthNav";
import ForgotPassword from "../components/Auth/ForgotPassword";
import OTPVerification from "../components/Auth/OTPVerification";
import Signup from "../components/Auth/Signup";

const AuthLayout = () => {
  return (
    
    <div className="flex w-full items-center justify-center  min-h-screen">
      
      <div className=" flex flex-col md:flex-row max-md:w-[90%] max-w-screen  bg-white h-screen overflow-hidden">
       <div  className="w-[90%] mx-auto md:w-1/2 h-screen">
        <AuthNav/>
           <Routes>
            <Route path="/" element={<AuthForm />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/password" element={<ForgotPassword />} />
            <Route path="/otp" element={<OTPVerification />} />

          </Routes>
          </div >
        <div
          className="hidden w-1/2 md:flex flex-col items-center justify-center p-8 bg-cover bg-gray-50"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <img src={img} alt="XReality Illustration" className="object-cover w-2/3" />
          <h3 className="text-xl font-semibold text-gray-800 mt-4">XReality Education Platform</h3>
          <p className="text-gray-600    text-center mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua.
          </p>
        </div>
      </div>
    </div>
    
  );
};

export default AuthLayout;
