import React from "react";

const Message = ({ message }) => {
  const isUserMessage = message.sender === "user"; // Assuming message sender is either 'user' or 'ai'

  return (
    <div
      className={`flex ${
        isUserMessage ? "justify-end" : "justify-start"
      } mb-4`}
    >
      <div
        className={`max-w-xs px-4 py-3 rounded-lg shadow ${
          isUserMessage
            ? "bg-purple-600 text-white"
            : "bg-gray-200 text-gray-800"
        }`}
      >
        <p className="text-sm">{message.text}</p>
      </div>
    </div>
  );
};

export default Message;
