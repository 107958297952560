import React, { useState } from "react";

import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import DashHome from '../pages/Dasboard/DashHome.jsx'
import Sidebar from "../components/Dashboard/Sidebar.jsx";
import { FaBars } from "react-icons/fa";
import AssistantLayout from "./AssistantLayout"
import NotificationPage from "../pages/Dasboard/NotificationPage.jsx";
import DashNav from "../components/Dashboard/DashNav.jsx";

const Dashboardlayout = () => {
  // State to control sidebar visibility on mobile
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Toggle sidebar visibility
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex min-h-screen bg-white">
   <div className="max-md:hidden">
    <Sidebar />
    </div>      {/* Overlay for mobile sidebar */}
    
      {/* Main content area */}
      <div className="flex-1 flex flex-col ">
        <DashNav />
    
        <main className="flex-1 p-4">
          <Routes>
            <Route path="/" element={<DashHome />} />
            <Route path="/assistant" element={<AssistantLayout/>} />
            <Route path="/notification" element={<NotificationPage/>} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Dashboardlayout;