import React, { useState } from "react";

const notifications = [
  { id: 1, name: "Wajiha Wahaj", action: "added a new assignment", subject: "in Physics.", time: "08:46pm", day: "Today" },
  { id: 2, name: "Wajiha Wahaj", action: "added a new assignment", subject: "in Physics.", time: "08:46pm", day: "Today" },
  { id: 3, name: "Wajiha Wahaj", action: "added a new assignment", subject: "in Physics.", time: "08:46pm", day: "Yesterday" },
  { id: 4, name: "Wajiha Wahaj", action: "added a new assignment", subject: "in Physics.", time: "08:46pm", day: "Yesterday" },
];

const NotificationItem = ({ notification }) => (
  <div className="flex   items-center justify-between py-4">
    <div className="flex items-center space-x-3">
      <img
        src="https://via.placeholder.com/40"
        alt="profile"
        className="w-10 h-10 rounded-full"
      />
      <p className="text-gray-700 text-sm">
        <span className="font-medium">{notification.name}</span> {notification.action}{" "}
        <span className="text-purple-600 font-medium">{notification.subject}</span>
      </p>
    </div>
    <span className="text-gray-500 text-xs">{notification.time}</span>
  </div>
);

const NotificationPage = () => {
  const [activeTab, setActiveTab] = useState("New");

  return (
    <div className="max-w-6xl h-screen mx-auto bg-white p-6 rounded-lg ">
      <h2 className="text-lg font-semibold text-gray-800">Notifications</h2>
      <div className="flex border-b border-gray-200 mt-4">
        {["All", "New", "Unread"].map((tab) => (
          <button
            key={tab}
            onClick={() => setActiveTab(tab)}
            className={`text-sm font-semibold px-4 py-2 ${
              activeTab === tab ? "text-purple-600 border-b-2 border-purple-600" : "text-gray-500"
            }`}
          >
            {tab}
          </button>
        ))}
      </div>

      <div className="mt-4">
        <p className="text-xs font-semibold text-gray-500">Today</p>
        {notifications
          .filter((n) => n.day === "Today")
          .map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
      </div>

      <div className="mt-4">
        <p className="text-xs font-semibold text-gray-500">Yesterday</p>
        {notifications
          .filter((n) => n.day === "Yesterday")
          .map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
      </div>
    </div>
  );
};

export default NotificationPage;
